import '../../node_modules/owl.carousel/dist/owl.carousel.min';
import 'magnific-popup';

jQuery(document).ready(function ($) {
    $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: [
            '<i class="fas fa-chevron-left"><span class="sr">Previous</span></i>',
            '<i class="fas fa-chevron-right"><span class="sr">Next</span></i>'
        ],
        responsive: {
            0: {
                items: 1
            },
            700: {
                items: 2
            }
        }
    })

    $('.scroll').on('click', function (e) {
        const TARGET = $(this).attr('href');

        e.preventDefault();

        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });



    var hideSections = function () {
        $('.bit-follow-section-wrapper').hide();
        $('.bit-play-my-city-wrapper').hide();
    
        // Hide the SVG container div by targeting the SVG's unique a tag href attribute
        $("a[href*='bandsintown.com']").closest("div[style='padding: 10px; text-align: right;']").hide();
    };
    
    hideSections();
    
    // Ensuring the method runs after a brief moment to catch dynamically loaded elements
    setTimeout(hideSections, 200);

});